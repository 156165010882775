import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { Icon } from '@zenchef/ds-react'
import { Stack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { HoverDelay, Label, Loader, Text } from '@zenchef/ui'
import { isAfter } from 'date-fns'
import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { Box, Flex } from 'rebass'
import { withTheme } from 'styled-components'

import GlobalErrorAlerts from '@/components/GlobalErrorAlerts'
import Alert from '@/components/redesign/common/Alert'
import bugsnagClient from '@/lib/bugsnag'
import BodyPortal from '@/utils/BodyPortal'
import errorMessageHandler from '@/utils/errorMessageHandler'
import { formatTimeString } from '@/utils/formatDateTime'
import getDateFnsLocal from '@/utils/getDateFnsLocal'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

import CVC from './CVC'
import ValidateButton from './ValidateButton'

const stripeElementStyle = {
  base: {
    '::placeholder': {
      fontSize: '12px',
      color: '#abacaf'
    }
  }
}

const CheckoutForm = withTheme(
  observer(({ theme }) => {
    const state = useLocalObservable(() => ({
      setupIntent: null,
      isLoading: false,
      elementsReady: 0,
      hoveringCVCTooltip: false,
      errorMessage: null,
      isFinished: false
    }))
    const { t } = useTranslation()
    const router = useRouter()
    const stripe = useStripe()
    const elements = useElements()
    const { appStore } = useContext(StoresContext)

    const submit = async (ev) => {
      if (state.isLoading || state.isFinished) {
        return
      }
      state.isLoading = true

      try {
        const { setupIntent, error } = await stripe?.confirmCardSetup(appStore.state.stripe_client_secret, {
          payment_method: {
            card: elements?.getElement(CardNumberElement),
            billing_details: { name: `${appStore.state.formData.lastname} ${appStore.state.formData.firstname}` }
          }
        })
        if (error) {
          state.errorMessage = errorMessageHandler({ error })
          state.isLoading = false
          return
        }

        appStore.state.setupIntentId = setupIntent.id
        appStore.confirmImprint()

        state.isFinished = true
        router.push({
          pathname: '/thank_you',
          query: appStore.state.query
        })
      } catch (error) {
        handleError(error)
      }
    }

    const checkError = (validator) => (state.errorMessage = validator && validator.error ? validator.error.message : '')

    const hoverCVC = (value) => (state.hoveringCVCTooltip = value)

    const locale = getDateFnsLocal(appStore.state.language)

    let cancellationTime
    let textualDate
    let isLate
    if (appStore.state.ebType === 'preauth') {
      cancellationTime = formatTimeString(
        formatToTimeZone(appStore.state.calculatedChargeTimeCancel, 'HH:mm', {
          locale,
          timeZone: appStore.state.restaurantTimezone
        }),
        appStore.state.language
      )
      textualDate = formatToTimeZone(appStore.state.calculatedChargeTimeCancel, 'dddd D MMMM', {
        locale,
        timeZone: 'UTC'
      })

      isLate = isAfter(new Date(), appStore.state.calculatedChargeTimeCancel)
    }

    return (
      <>
        <Box style={{ display: state.elementsReady >= 3 ? 'block' : 'none' }}>
          <Stack gap='10px' mb='10px'>
            <GlobalErrorAlerts />
          </Stack>
          <Label css='text-transform:uppercase;letter-spacing:0.5px;' fontSize='xxs' color='label.black.0' mb='5px'>
            {t('credit_card')}
          </Label>
          <Flex justifyContent='space-between' flexDirection={['column', 'row']}>
            <CardNumberElement
              onReady={(el) => state.elementsReady++}
              className='card-number'
              options={{ showIcon: true, preferredNetwork: ['cartes_bancaires'] }}
              placeholder={t('credit_card_number')}
              style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
              onChange={checkError}
            />
            <Flex flexDirection={['column', 'row']} id='expiry-and-cvc-container'>
              <CardExpiryElement
                className='card-expiry'
                placeholder={t('mm_yy')}
                style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
                onReady={(el) => state.elementsReady++}
                onChange={checkError}
              />
              <Flex alignItems='center' justifyContent='space-between' id='cvc-container'>
                <CardCvcElement
                  placeholder={t('cvc')}
                  className='card-cvc'
                  style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
                  onReady={(el) => state.elementsReady++}
                  onChange={checkError}
                />
                <Manager>
                  <Reference>
                    {({ ref }) => (
                      <Observer>
                        {() => (
                          <HoverDelay onMouseOver={() => hoverCVC(true)} onMouseOut={() => hoverCVC(false)} delay={300}>
                            <div>
                              <Box ref={ref}>
                                <Icon
                                  name='help-question-circle'
                                  fontSize='16px'
                                  marginRight='7px'
                                  color='restaurant.onWhiteBackground'
                                />
                              </Box>
                            </div>
                          </HoverDelay>
                        )}
                      </Observer>
                    )}
                  </Reference>
                  <Popper placement='bottom-start'>
                    {({ ref, style, placement, arrowProps }) => (
                      <BodyPortal>
                        <Observer>
                          {() =>
                            state.hoveringCVCTooltip && (
                              <>
                                <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                                  <CVC />
                                </div>
                                <div ref={arrowProps.ref} style={arrowProps.style} />
                              </>
                            )
                          }
                        </Observer>
                      </BodyPortal>
                    )}
                  </Popper>
                </Manager>
              </Flex>
            </Flex>
          </Flex>
          {state.errorMessage && (
            <Text mt='4px' color='red.default'>
              {state.errorMessage}
            </Text>
          )}
        </Box>
        {state.elementsReady < 3 || !appStore.state.quotation.id ? (
          <Loader m='20px auto 10px' bounceColor={token.var('colors.restaurant.onWhiteBackground')} />
        ) : (
          <>
            {!isLate ? (
              appStore.state.ebType === 'preauth' ? (
                <Alert
                  type='information'
                  {...safelySetInnerHTML(
                    t('eb_amount_disclaimer', {
                      textualDate,
                      hoursDuration: cancellationTime,
                      amount: appStore.state.quotationAmountFormatted
                    })
                  )}
                />
              ) : (
                <Alert type='information' {...safelySetInnerHTML(t('offers.cancelation_disclaimer'))} />
              )
            ) : (
              <>
                {appStore.state.ebType === 'preauth' && (
                  <Alert
                    type='error'
                    {...safelySetInnerHTML(
                      t('booking_charges_not_cancelable_warning_message', {
                        eb_amount_no_shown: appStore.state.quotationAmountFormatted
                      })
                    )}
                  />
                )}
              </>
            )}
            <Alert type='warning' {...safelySetInnerHTML(t('3ds_warning'))} />

            <Flex justifyContent='center'>
              <ValidateButton testId='validate' display='flex' onClick={submit}>
                {state.isLoading ? (
                  <Loader bounceColor='white.default' />
                ) : (
                  <>
                    <Icon name='lock-closed' fontSize='18px' mr='1' />
                    <Text>{t('validate_my_booking')}</Text>
                  </>
                )}
              </ValidateButton>
            </Flex>
          </>
        )}
        <style jsx global>{`
          #cvc-container {
            width: 50%;
          }

          #expiry-and-cvc-container {
            border: ${theme.borderWidths[1]} solid #dbd9da;
            border-radius: ${theme.radii[1]}px;
            width: calc(35% - 15px);
          }

          .card-number {
            padding: 10px;
            border-radius: ${theme.radii[1]}px;
            border: ${theme.borderWidths[1]} solid #dbd9da;
            width: 65%;
          }

          .card-expiry {
            padding: 10px;
            width: 50%;
          }

          .card-cvc {
            border-left: ${theme.borderWidths[1]} solid #dbd9da;
            padding: 10px 0 10px 10px;
            width: 65%;
          }

          @media (max-width: 499px) {
            #cvc-container {
              width: 100%;
              position: relative;
            }

            #ic-help {
              position: absolute;
              right: 0;
              top: 8px;
            }

            #expiry-and-cvc-container {
              width: 100%;
              border: none;
            }

            .card-number {
              width: 100%;
            }

            .card-expiry {
              border: ${theme.borderWidths[1]} solid #dbd9da;
              width: 100%;
              border-radius: ${theme.radii[1]}px;
            }

            .card-cvc {
              border: ${theme.borderWidths[1]} solid #dbd9da;
              width: 100%;
              border-radius: ${theme.radii[1]}px;
            }

            .StripeElement:not(.card-cvc) {
              margin-bottom: 10px;
            }
          }
        `}</style>
      </>
    )
    function isNetworkError(error) {
      return error.type === 'fetch_error' || error.message === 'Network Error'
    }
    function handleError(error) {
      state.errorMessage = errorMessageHandler(error.response?.data || {})
      state.isLoading = false
      appStore.state.error = null
      appStore.state.paymentMethodId = null
      appStore.state.paymentIntentId = null
      if (!isNetworkError(error)) {
        console.error(error)
        bugsnagClient.notify(error)
      }
    }
  })
)

export default CheckoutForm
