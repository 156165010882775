import { Stack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { observer } from 'mobx-react-lite'
import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { CSSObject } from 'styled-components'

import { BWMessageEvent, BWMessageEventWithData, SUM_PADDING_SDK, WIDE_ROUTES } from '@/utils/constants'
import { useScrollToTop, useTranslation } from '@/utils/hooks'
import useSdkEvents from '@/utils/hooks/useSdkEvents'
import StoresContext from '@/utils/StoresContext'

import MainButton from './MainButton'
import ModalWrapper from './ModalWrapper'

interface MainLayoutProps extends PropsWithChildren {
  headerRight?: ReactNode
  modalBodyStyle?: CSSObject
  hideFooter?: boolean
  footer?: ReactNode
}

const MIN_HEIGHT = 40 + SUM_PADDING_SDK
const VERY_LARGE_HEIGHT = 1200

const computeContentHeight = (content: Element) => {
  const rect = content.getBoundingClientRect()
  return Math.max(Math.ceil(rect.height ?? rect.bottom - rect.top), MIN_HEIGHT)
}
const computeHeightElementShouldTake = (elementHeight: number, parentHeight: number | null) => {
  return Math.min(elementHeight, parentHeight ?? Infinity)
}

const MainLayout = observer<MainLayoutProps>(({ children, headerRight, modalBodyStyle, hideFooter, footer }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { appStore } = useContext(StoresContext)
  const { sdk, isCollapsed, isFullscreen } = appStore.state
  const { sendWidgetListening, canSendEvents, sendHeight, sendWidth } = useSdkEvents()
  const isWhiteLabel = appStore.state.is_white_label
  const DISAPEARRING_STYLE = {
    transition: 'opacity 0.2s ease-in',
    opacity: isCollapsed ? '0' : '1'
  }

  const [hideContent, setHideContent] = useState(isCollapsed)
  const [bump, setBump] = useState(false)

  const parentHeight = useRef<number | null>(null)

  const open = useCallback(() => {
    // Should we refresh the data then ?
    appStore.setIsCollapsed(false)
    setHideContent(false)
  }, [appStore])

  const close = useCallback(() => {
    appStore.resetIsCollapsed()
  }, [appStore])

  const lastContentHeightWrapper = useRef<number | null>(null)
  const timeoutSendHeightWrapper = useRef<NodeJS.Timeout | null>(null)

  useScrollToTop()

  useEffect(() => {
    const modalBody = document.getElementById('main-modal-body')
    if (modalBody) {
      const content = modalBody.querySelector('.content')
      if (content && modalBody.style.height === '') {
        modalBody.style.height =
          computeHeightElementShouldTake(computeContentHeight(content), parentHeight.current) + 'px'
      }

      if (content && window.ResizeObserver && !isCollapsed) {
        const handleHeightChange = () => {
          const newContentHeight = computeContentHeight(content)
          const lastContentHeight = lastContentHeightWrapper.current

          lastContentHeightWrapper.current = newContentHeight
          // If the height didn't change, don't do anything
          if (lastContentHeight === newContentHeight) {
            return
          }

          if (timeoutSendHeightWrapper.current) {
            clearTimeout(timeoutSendHeightWrapper.current)
            timeoutSendHeightWrapper.current = null
          }

          if (canSendEvents) {
            const isIncreasing = newContentHeight > (lastContentHeight ?? 0)

            if (isIncreasing) {
              sendHeight(parentHeight.current ?? VERY_LARGE_HEIGHT)
            }

            timeoutSendHeightWrapper.current = setTimeout(() => {
              const modalHeader = document.getElementById('main-modal-header')
              const modalFooter = document.getElementById('main-modal-footer')
              const newModalHeight = computeHeightElementShouldTake(
                newContentHeight + (modalHeader?.offsetHeight ?? 0) + (modalFooter?.offsetHeight ?? 0),
                parentHeight.current
              )

              sendHeight(newModalHeight)
            }, 500)
          }
          modalBody.style.height = computeHeightElementShouldTake(newContentHeight, parentHeight.current) + 'px'
        }
        let prevHeight = 0

        const resizeObserver = new window.ResizeObserver(function (entries) {
          // Filter only the height changes, not the width
          entries.forEach((entry) => {
            // fallback to borderBoxSize old implementation for firefox support
            // cf https://bugzilla.mozilla.org/show_bug.cgi?id=1689645
            const resizeObserverSize = entry.borderBoxSize?.[0] ?? entry.borderBoxSize
            const height = resizeObserverSize?.blockSize ?? entry.contentRect?.height

            if (typeof height === 'number' && height !== prevHeight) {
              prevHeight = height
              handleHeightChange()
            }
          })
        })

        resizeObserver.observe(content)

        return () => {
          resizeObserver.disconnect()
        }
      }
    }
  }, [canSendEvents, isCollapsed, sendHeight])

  useLayoutEffect(() => {
    if (window !== undefined && sdk) {
      const handleMessageReceived = (event: MessageEvent) => {
        if (!(event instanceof MessageEvent)) {
          return
        }
        switch (event.data) {
          case BWMessageEvent.OPEN: {
            if (isCollapsed) {
              if (appStore.state.showCollapsed) {
                open()
              }
            } else if (!bump) {
              setBump(true)
              setTimeout(() => setBump(false), 1000)
            }
            break
          }
          case BWMessageEvent.CLOSE: {
            if (!isCollapsed && appStore.state.showCollapsed) {
              close()
            }
            break
          }
        }
        switch (event.data?.type) {
          case BWMessageEventWithData.SDK_LOCATION_HREF: {
            appStore.setSdkLocationHref(event.data.sdkLocationHref)
            break
          }
          case BWMessageEventWithData.SEND_PARENT_HEIGHT: {
            parentHeight.current = event.data.height
            break
          }
        }
      }

      window.addEventListener('message', handleMessageReceived)

      return () => window.removeEventListener('message', handleMessageReceived)
    }
  }, [close, isCollapsed, open, sdk, appStore.state.showCollapsed, bump, appStore])

  useLayoutEffect(() => {
    if (isCollapsed) {
      if (!hideContent) {
        const modalBody = document.getElementById('main-modal-body')
        if (modalBody) {
          // we need to reset height to have a height animation on reopen
          modalBody.style.height = MIN_HEIGHT + 'px'
        }
        setTimeout(() => {
          setHideContent(true)
        }, 200)
      }
    }
  }, [hideContent, isCollapsed])

  useEffect(() => {
    sendWidgetListening()
  }, [sendWidgetListening])

  useEffect(() => {
    if (!isCollapsed) {
      sendWidth(router.pathname)
    }
  }, [isCollapsed, router.pathname, sendWidth])

  useEffect(() => {
    if (isCollapsed) {
      lastContentHeightWrapper.current = null
    }
  }, [isCollapsed])

  const isWide = WIDE_ROUTES.includes(router.pathname.replace('/', ''))

  return (
    <>
      <Head>
        <title>{t('meta_title', { restaurant_name: appStore.state.name })}</title>
        <meta
          name='description'
          content={t('meta_description', {
            restaurant_name: appStore.state.name,
            restaurant_city: appStore.state.city
          })}
        />
      </Head>
      {hideContent && <MainButton key='main-button' openWidget={open} />}
      <ModalWrapper
        key='main-modal'
        bump={bump}
        headerRight={headerRight}
        modalStyle={{
          width: `100%`,
          minHeight: isFullscreen ? '100vh' : sdk ? '0' : undefined,
          overflow: sdk ? 'hidden' : undefined,
          display: hideContent ? 'none' : undefined,
          ...DISAPEARRING_STYLE
        }}
        modalPositionStyle={{
          width: isFullscreen
            ? '100vw'
            : sdk
              ? '100%'
              : isWide
                ? token.var('sizes.wide-width')
                : token.var('sizes.narrow-width')
        }}
        modalBodyStyle={{
          borderRadius:
            hideFooter || (isWhiteLabel && !footer && !isFullscreen)
              ? `0 0 ${token('radii.xl')} ${token('radii.xl')}`
              : '0',
          ...modalBodyStyle
        }}
        hideFooter={hideFooter || hideContent}
        footer={footer}>
        {!hideContent && <Stack gap='gap.0'>{children}</Stack>}
      </ModalWrapper>
    </>
  )
})

export default MainLayout
