import { RichText } from '@zenchef/ds-react'
import { Flex } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import Alert from '@/components/redesign/common/Alert'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

interface RestaurantCommentProps {
  specific?: boolean
}

const RestaurantComment = observer(({ specific }: RestaurantCommentProps) => {
  const { appStore } = useContext(StoresContext)
  const { restaurantComment, restaurantSpecificComment } = appStore.state
  const { translateField } = useTranslation()

  if (specific) {
    const content = restaurantSpecificComment ? translateField(restaurantSpecificComment) : null
    return content ? <Alert type='neutral' {...safelySetInnerHTML(content)} /> : null
  }

  const content = restaurantComment ? translateField(restaurantComment) : null

  return content ? (
    <Flex px={{ base: 'padding.4', _containerS: 'padding.6' }} width='100%'>
      <RichText color='content.neutral.subtle' textAlign='center' {...safelySetInnerHTML(content)} />
    </Flex>
  ) : null
})

RestaurantComment.displayName = 'RestaurantComment'

export default RestaurantComment
