import { Icon } from '@zenchef/ds-react'
import { cva } from '@zenchef/styled-system/css'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'

import { Button } from '@/components/redesign/common/Button'
import ButtonGroup from '@/components/redesign/common/ButtonGroup'
import ZenchefLogo from '@/components/redesign/common/ZenchefLogo'
import useSearchBookingWish from '@/components/redesign/filters/useSearchBookingWish'
import { isTablebookerVoucherParam } from '@/types/types'
import getTablebookerShopUrl from '@/utils/getTablebookerShopUrl'
import useSdkEvents from '@/utils/hooks/useSdkEvents'
import useTranslation from '@/utils/hooks/useTranslation'
import StoresContext from '@/utils/StoresContext'

interface MainButtonProps {
  openWidget: () => void
}

const positionRecipe = cva({
  base: {},
  variants: {
    position: {
      left: {
        left: 'padding.sdk'
      },
      right: {
        right: 'padding.sdk'
      },
      center: {
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap'
      }
    }
  }
})

const ShopRedirectionButton = () => {
  const { appStore } = useContext(StoresContext)
  const { language, voucherParam, theme, isTestRestaurant = false } = appStore.state

  if (!isTablebookerVoucherParam(voucherParam)) return null

  const [, primaryColor] = theme.colors.primary?.split('#')

  const redirectToShop = () => {
    if (voucherParam.tablebooker_shop_id) {
      const shopUrl = getTablebookerShopUrl({
        isTestRestaurant,
        tablebookerShopId: voucherParam.tablebooker_shop_id,
        primaryColor,
        language
      })

      window.open(shopUrl, '_blank')
    }
  }

  return (
    <Button testId='shop' key='shop' onClick={redirectToShop} display='flex' border='none' alignItems='center' iconOnly>
      <Icon name='gift' fontSize='20px' />
    </Button>
  )
}

const MainButton = observer(({ openWidget }: MainButtonProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { appStore } = useContext(StoresContext)
  const { sendHeight, sendWidth } = useSdkEvents()
  const { accordionItemToOpen } = useSearchBookingWish()

  const { is_white_label: isWhiteLabel } = appStore.state

  const buttonGroupRef = useRef<HTMLDivElement>(null)
  const iframePosition = appStore.state.query.iframePosition ?? 'right'

  useEffect(() => {
    if (buttonGroupRef.current) {
      sendWidth(buttonGroupRef.current.offsetWidth)
      sendHeight(buttonGroupRef.current.offsetHeight)
    }
  }, [buttonGroupRef.current?.offsetWidth, buttonGroupRef.current?.offsetHeight, sendWidth, sendHeight])

  const redirectToZenchef = () => {
    window.open(appStore.getMarketingLink('zenchefLogo-mainButton'), '_blank')
  }

  return (
    <ButtonGroup
      data-panda-theme='whiteLabel'
      testId='zenchef'
      ref={buttonGroupRef}
      position='fixed'
      bottom='padding.sdk'
      className={positionRecipe({ position: iframePosition })}
      shadow='effect.shadow.bold'
      hierarchy='primary'
      size='normal'>
      {!isWhiteLabel ? (
        <Button testId='zenchef' onClick={redirectToZenchef} key='zenchef' display='flex' alignItems='center' iconOnly>
          <ZenchefLogo />
        </Button>
      ) : null}
      <Button
        testId='bookings'
        onClick={async () => {
          if (accordionItemToOpen) {
            await router.push({ query: { ...router.query, currentFilter: accordionItemToOpen } })
          }
          openWidget()
        }}
        key='bookings'>
        {t('bookings_main_button_label')}
      </Button>
      <ShopRedirectionButton />
    </ButtonGroup>
  )
})

export default MainButton
