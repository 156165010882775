import { Icon } from '@zenchef/ds-react'
import { IconName } from '@zenchef/icomoon'
import { RecipeVariantProps, sva } from '@zenchef/styled-system/css'
import { HTMLStyledProps, styled } from '@zenchef/styled-system/jsx'
import { ElementRef, forwardRef } from 'react'

type AlertType = 'neutral' | 'information' | 'success' | 'warning' | 'error'
const ALERT_ICON = {
  neutral: undefined,
  information: 'info-circle',
  warning: 'alert-circle',
  error: 'alert-triangle',
  success: 'check-01-circle'
} as const satisfies Record<AlertType, IconName | undefined>

// TODO: move into ds-react
const alertStyle = sva({
  slots: ['icon', 'title', 'description', 'root', 'content'],
  base: {
    root: {
      border: 'm',
      borderRadius: 'l',
      display: 'flex',
      gap: 'gap.2',
      px: 'padding.4',
      py: 'padding.1,5',
      textStyle: 'paragraph.m.regular',
      textAlign: 'left',
      alignItems: 'center',
      color: 'content.neutral.base.bold',
      _withinWhiteLabelTheme: {
        color: 'content.neutral.subtle',
        borderColor: 'border.neutral-on-brand.bold',
        backgroundColor: 'transparent'
      }
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 'gap.1',
      py: 'padding.1'
    },
    icon: {
      alignSelf: 'flex-start',
      fontSize: 'icon.base',
      lineHeight: 'token(sizes.xl)',
      _withinWhiteLabelTheme: {
        color: 'content.neutral.subtle'
      }
    },
    title: {
      textStyle: 'title.s',
      _withinWhiteLabelTheme: {
        color: 'content.neutral.bold'
      }
    },
    description: {
      textStyle: 'paragraph.m.regular',
      _withinWhiteLabelTheme: {
        color: 'content.neutral.subtle'
      }
    }
  },
  variants: {
    type: {
      neutral: {
        root: {
          borderColor: 'border.neutral.bold',
          backgroundColor: 'background.neutral.base.subtler'
        },
        icon: {
          color: 'content.neutral.base.subtle'
        }
      },
      information: {
        root: {
          borderColor: 'border.functional.info',
          backgroundColor: 'background.functional.info.subtle'
        },
        icon: {
          color: 'content.functional.info'
        }
      },
      success: {
        root: {
          borderColor: 'border.functional.success',
          backgroundColor: 'background.functional.success.subtle'
        },
        icon: {
          color: 'content.functional.sucess'
        }
      },
      warning: {
        root: {
          borderColor: 'border.functional.warning',
          backgroundColor: 'background.functional.warning.subtle'
        },
        icon: {
          color: 'content.functional.warning'
        }
      },
      error: {
        root: {
          borderColor: 'border.functional.error',
          backgroundColor: 'background.functional.error.subtle'
        },
        icon: {
          color: 'content.functional.error'
        }
      }
    }
  },

  defaultVariants: {
    type: 'neutral'
  }
})

type AlertProps = Omit<HTMLStyledProps<'div'>, 'className' | 'children' | 'dangerouslySetInnerHTML'> &
  RecipeVariantProps<typeof alertStyle> & { iconName?: IconName } & (
    | {
        children?: React.ReactNode
        dangerouslySetInnerHTML?: never
        title?: never
        description?: never
      }
    | { dangerouslySetInnerHTML?: { __html: string }; children?: never; description?: never }
    | { title: string; description: string; children?: never; dangerouslySetInnerHTML?: never }
  )

const Alert = forwardRef<ElementRef<'div'>, AlertProps>(
  ({ children, dangerouslySetInnerHTML, title, description, type, iconName: iconNameFromProps, ...props }, ref) => {
    const alertType = type || 'neutral'
    const iconName = ALERT_ICON[alertType] ?? iconNameFromProps ?? null
    const classes = alertStyle({ type })
    return (
      <styled.div className={classes.root} {...props} ref={ref}>
        {iconName && <Icon name={iconName} className={classes.icon} />}
        <div className={classes.content}>
          {title || description ? (
            <>
              {title && <div className={classes.title}>{title}</div>}
              {description && <div className={classes.description}>{description}</div>}
            </>
          ) : dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          ) : (
            children
          )}
        </div>
      </styled.div>
    )
  }
)

Alert.displayName = 'Alert'

export default Alert
