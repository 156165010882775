import { differenceInMilliseconds, format } from 'date-fns'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import Alert from '@/components/redesign/common/Alert'
import getTestId from '@/utils/getTestId'
import StoresContext from '@/utils/StoresContext'

interface Props {
  bookingExpiresDate: Date
}

const BookingAlreadyExistsAlert = ({ bookingExpiresDate }: Props) => {
  const router = useRouter()
  const { appStore } = useContext(StoresContext)

  const distanceInMs = differenceInMilliseconds(bookingExpiresDate, Date.now())
  const formattedMinutes = format(distanceInMs, 'mm:ss')

  const [countdown, setCountdown] = useState(distanceInMs)

  const cleanStore = useCallback(() => {
    appStore.state.specificErrorKey = null
    appStore.state.bookingExpiresDate = null
  }, [appStore.state])

  const onRedirect = () => {
    cleanStore()
    router.push({ pathname: '/results', query: appStore.state.query })
  }

  // Rerender to show the countdown dynamically
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1000)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [countdown])

  // When the countdown is over, alert should be hidden
  useEffect(() => {
    if (distanceInMs <= 0) {
      cleanStore()
    }
  }, [distanceInMs, cleanStore])

  return (
    <Alert data-testid={getTestId('booking-already-exists-countdown', 'alert')} type='error' whiteSpace='pre-line'>
      <Trans
        parent='div'
        i18nKey='booking_expires_at'
        ns='validation'
        components={{
          linkTag: <a href='#' onClick={onRedirect} />
        }}
        values={{ bookingExpiresAt: formattedMinutes }}
      />
    </Alert>
  )
}

export default React.memo(BookingAlreadyExistsAlert)
