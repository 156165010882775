import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { useContext } from 'react'
import { DayModifiers } from 'react-day-picker'

import useTranslation from '@/utils/hooks/useTranslation'
import StoresContext from '@/utils/StoresContext'

const useDayModifiers = () => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const convertUTCDateToDateString = (date) => {
    const localDate = new Date(date)
    localDate.setTime(localDate.getTime() + localDate.getTimezoneOffset() * 60000)
    return formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: 'UTC' })
  }

  const modifiersFunctions = {
    open: (date) => appStore.state.isSummaryDateAvailableForCurrentPax(convertUTCDateToDateString(date)),
    full: (date) => appStore.state.isSummaryDateFull(convertUTCDateToDateString(date)),
    notOpenYet: (date) => appStore.state.isSummaryDateNotOpenYet(convertUTCDateToDateString(date)),
    notOpenAnymore: (date) => appStore.state.isSummaryDateNotOpenAnymore(convertUTCDateToDateString(date)),
    fullPax: (date) => appStore.state.isSummaryDateFullPax(convertUTCDateToDateString(date)),
    closed: (date) => appStore.state.isSummaryDateClosed(convertUTCDateToDateString(date)),
    waitlistOpen: (date) =>
      !appStore.state.isSummaryDateAvailableForCurrentPax(convertUTCDateToDateString(date)) &&
      appStore.state.isSummaryDateWaitlistAvailableForCurrentPax(convertUTCDateToDateString(date)),
    past: (date) => appStore.state.isDateInThePast(convertUTCDateToDateString(date)),
    today: (date) => appStore.state.isDateToday(convertUTCDateToDateString(date))
  }

  const modifiersLabels = {
    full: {
      title: t('tooltip.full')
    },
    fullPax: {
      title: t('tooltip.fullPax', {
        pax: appStore.state.wish.pax,
        count: appStore.state.wish.pax
      })
    },
    waitlistOpen: {
      title: t('tooltip.waitlistOpen', {
        pax: appStore.state.wish.pax,
        count: appStore.state.wish.pax
      })
    },
    notOpenYet: {
      title: t('tooltip.notOpenYet')
    },
    notOpenAnymore: {
      title: t('tooltip.notOpenAnymore')
    },
    closed: {
      title: t('tooltip.closed')
    },
    past: {
      title: t('tooltip.past')
    }
  }

  const getModifierTooltip = (dayModifiers: Partial<DayModifiers>, field = 'title') => {
    if (dayModifiers.past) {
      return modifiersLabels.past[field]
    } else if (dayModifiers.notOpenYet) {
      return modifiersLabels.notOpenYet[field]
    } else if (dayModifiers.notOpenAnymore) {
      return modifiersLabels.notOpenAnymore[field]
    } else if (dayModifiers.waitlistOpen) {
      return modifiersLabels.waitlistOpen[field]
    } else if (dayModifiers.closed) {
      return modifiersLabels.closed[field]
    } else if (dayModifiers.full) {
      return modifiersLabels.full[field]
    } else if (dayModifiers.fullPax) {
      return modifiersLabels.fullPax[field]
    }
  }

  const getDayTooltip = (date: Date) => {
    const dayModifiers = {
      past: modifiersFunctions.past(date),
      notOpenYet: modifiersFunctions.notOpenYet(date),
      notOpenAnymore: modifiersFunctions.notOpenAnymore(date),
      waitlistOpen: modifiersFunctions.waitlistOpen(date),
      closed: modifiersFunctions.closed(date),
      full: modifiersFunctions.full(date),
      fullPax: modifiersFunctions.fullPax(date)
    }

    return getModifierTooltip(dayModifiers)
  }

  return {
    modifiersFunctions,
    getModifierTooltip,
    getDayTooltip
  }
}

export default useDayModifiers
