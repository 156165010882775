import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import Alert from '@/components/redesign/common/Alert'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

export default observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  if (!appStore.state.hasPrecharge) return null
  return <Alert type='information'>{t('minguest_eb_reminder')}</Alert>
})
