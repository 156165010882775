import { Icon } from '@zenchef/ds-react'
import { cx, sva } from '@zenchef/styled-system/css'
import Router from 'next/router'
import { useContext } from 'react'

import Scrollable from '@/components/Scrollable'
import getLanguageFlagFromIso from '@/utils/getLanguageFlagFromIso'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const languageDropdownRecipe = sva({
  slots: ['container', 'item', 'title', 'flag'],
  base: {
    container: {
      bg: 'background.neutral.base.subtlest',
      py: 'padding.2',
      gap: 'gap.2',
      borderRadius: 'l',
      border: 'm',
      borderColor: 'border.neutral.subtle',
      shadow: 'effect.shadow.subtle',
      maxHeight: '[364px]',
      width: '[240px]',
      display: 'flex',
      flexDirection: 'column',
      animation: 'fadeInUp'
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 'l',
      height: 'xl',
      px: 'padding.3',
      mx: 'padding.2',
      cursor: 'pointer',
      _hover: {
        bg: 'background.neutral.base.subtlest-hover'
      },
      _aromaticonChildren: {
        color: 'content.brand.subtle'
      }
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: 'gap.2',
      textStyle: 'paragraph.m.regular',
      color: 'content.neutral.base.bold'
    },
    flag: {
      borderRadius: 'xs',
      shadow: 'none'
    }
  }
})

const LanguageDropdown = (props) => {
  const { appStore } = useContext(StoresContext)
  const { language: currentLanguage } = appStore.state
  const { i18n } = useTranslation()
  const changeLanguage = (language) => {
    const query = { ...Router.query, lang: language }
    if (language === appStore.state.restaurantLanguage) {
      delete query.lang
    }
    i18n.changeLanguage(language).then(() => {
      appStore.state.language = language
      props.onLanguageChosen(language)
      Router.push({
        pathname: Router.pathname,
        query
      })
    })
  }
  const classes = languageDropdownRecipe()

  return (
    <ul className={classes.container}>
      <Scrollable fancyScrollbar>
        {appStore.state.language_availabilities.map((language) => {
          return (
            <li key={language} id={language} onClick={() => changeLanguage(language)} className={classes.item}>
              <div className={classes.title}>
                {language !== 'en' ? (
                  <div className={cx(`iti-flag ${getLanguageFlagFromIso(language)}`, classes.flag)} />
                ) : (
                  <div className={cx('merged-iti-flags', classes.flag)}>
                    <div className='iti-flag gb' />
                    <div className='iti-flag us' />
                  </div>
                )}
                <span>{i18n.t(`language.${language}`)}</span>
              </div>

              {currentLanguage === language && (
                <Icon name='check-01' color='content.brand.subtle' data-panda-theme='whiteLabel' fontSize='20px' />
              )}
            </li>
          )
        })}
      </Scrollable>
    </ul>
  )
}

export default LanguageDropdown
