import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const getTablebookerShopUrl = ({
  isTestRestaurant,
  tablebookerShopId: tablebooker_shop_id,
  primaryColor,
  language
}: {
  isTestRestaurant: boolean
  tablebookerShopId: string
  primaryColor?: string
  language?: string
}) => {
  const queryParameters = new URLSearchParams({
    'active-collection': 'vouchers',
    collections: 'vouchers',
    'shop-id': tablebooker_shop_id,
    ...(language ? { language } : {}),
    ...(primaryColor ? { 'primary-color': primaryColor } : {})
  })

  const baseShopUrl = isTestRestaurant ? publicRuntimeConfig.SHOP_URL_TEST_RESTAURANT : publicRuntimeConfig.SHOP_URL
  const shopUrl = `${baseShopUrl}/?${queryParameters}`

  return shopUrl
}

export default getTablebookerShopUrl
